import DataTable, { createTheme } from "react-data-table-component";
import React from "react";
import DeliveriesByRegisterChart from "../DeliveriesByRegister/DeliveriesByRegisterChart";

createTheme("fuelIQ", {
  text: {
    primary: "#FFFFFF",
    secondary: "rgba(255, 255, 255, 0.7)",
    disabled: "rgba(0,0,0,.12)",
  },
  background: {
    default: "var(--darker)",
  },
  context: {
    background: "#E91E63",
    text: "#FFFFFF",
  },
  divider: {
    default: "rgba(81, 81, 81, 1)",
  },
  button: {
    default: "#FFFFFF",
    focus: "rgba(255, 255, 255, .54)",
    hover: "rgba(255, 255, 255, .12)",
    disabled: "rgba(0,0,0,.12)",
  },
  sortFocus: {
    default: "rgba(255, 255, 255, .54)",
  },
  selected: {
    default: "rgba(0, 0, 0, .7)",
    text: "#FFFFFF",
  },
  highlightOnHover: {
    default: "rgba(0, 0, 0, .7)",
    text: "#FFFFFF",
  },
  striped: {
    default: "rgba(0, 0, 0, .87)",
    text: "#FFFFFF",
  },
});

const customStyles = {
  headCells: {
    style: {
      fontWeight: 550,
      fontSize: 14,
    },
  },
  rows: {
    style: {
      fontSize: 14,
    },
  },
  pagination: {
    style: {
      color: "var(--text)",
    },
  },
};

const conditionalRowStyles = [
  {
    when: (cell: any) => parseFloat(cell.flowRateChange) <= -10,
    style: {
      color: "var(--accent)",
    },
  },
];

const columns = [
  {
    name: "Register ID",
    selector: "register",
  },
  {
    name: "# Transactions",
    selector: "numTransactions",
    minWidth: "35%",
  },
  {
    name: "Max. Flowrate (gal/min)",
    selector: "maxFlowrate",
    format: (row: any) => Math.round(row.maxFlowrate * 100) / 100,
  },
  {
    name: "Avg. Flowrate (gal/min)",
    selector: "avgFlowrate",
    format: (row: any) => Math.round(row.avgFlowrate * 100) / 100,
  },
  {
    name: "Change",
    selector: "flowRateChange",
    conditionalCellStyles: conditionalRowStyles,
    format: (row: any) => Math.round(row.flowRateChange * 100) / 100,
  },
];

interface TableProps {
  registers: Register[];
}

interface Register {
  register: number;
  numTransactions: number;
  maxFlowrate: string;
  avgFlowrate: string;
  maxCorrectedPressure: string;
}

const ExpanableComponent = ({ data }: any) => (
  <DeliveriesByRegisterChart deliveries={data.deliveries} />
);

export default class FlowrateTable extends React.Component<TableProps> {
  render() {
    return (
      <DataTable
        noHeader
        customStyles={customStyles}
        columns={columns}
        data={this.props.registers}
        pagination={true}
        theme="fuelIQ"
        expandableRows
        expandableRowsComponent={<ExpanableComponent />}
        paginationPerPage={25}
      />
    );
  }
}
