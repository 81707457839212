import React, { useState, useEffect } from "react";
import NumberFormat from "react-number-format";
import { observer } from "mobx-react";

import { Header } from "../components/layout/Header";
import { Sidebar } from "../components/layout/Sidebar";
import { Maps } from "../components/graphs/MapView/Maps";
import summaryStore from "../store/Summary";
import { Card } from "../components/ui/Card";
import { Number } from "../components/ui/Number";
import { FilterBar } from "../components/filter/Bar";
import { Container, Content, Body } from "../components/ui/Fundamentals";
import { StackedBarChart } from "../components/graphs/StackedBarChart";

export const Home: React.FC = observer(() => {
  const [summary] = useState(summaryStore);

  useEffect(() => {
    summary.fetch();
  }, [summary]);

  return (
    <Container>
      <Sidebar />
      <Body>
        <Content>
          <Header />
          <FilterBar
            limitRange
            filter={summary.filter}
            updateFilter={summary.updateFilter}
            onDownload={summary.download}
          />
          <Card column="span 3">
            {summary.company && (
              <Number label="Transactions">
                {summary.company.transactions}
              </Number>
            )}
            {summary.company && (
              <Number label="Gross Volume (in gal)">
                <NumberFormat
                  value={summary.company.grossVolume}
                  displayType={"text"}
                  thousandSeparator={true}
                />
              </Number>
            )}
            {summary.company && (
              <Number label="Net sales">
                <NumberFormat
                  value={summary.company.netSales}
                  displayType={"text"}
                  thousandSeparator={true}
                  prefix={"$"}
                />
              </Number>
            )}
          </Card>
          <Card column="span 9">
            {summaryStore.deliveries && <Maps data={summaryStore.deliveries} />}
          </Card>
          <Card column="span 12">
            {summaryStore.summaryByDay && (
              <StackedBarChart data={summaryStore.summaryByDay} />
            )}
          </Card>
        </Content>
      </Body>
    </Container>
  );
});
