import React, { useState, useEffect } from "react";
import NumberFormat from "react-number-format";
import { observer } from "mobx-react";
import { reaction } from "mobx";

import { Sidebar } from "../components/layout/Sidebar";
import { Header } from "../components/layout/Header";
import { Card } from "../components/ui/Card";
import Table from "../components/graphs/DataTables/DataTable";
import { Container, Content, Body } from "../components/ui/Fundamentals";
import { Number as ImportantNumber } from "../components/ui/Number";
import viewStore from "../store/View";
import { DeliveryStore } from "../store/Delivery";
import { FilterBar } from "../components/filter/Bar";

export const Transactions: React.FC = observer(() => {
  const [deliveryStore] = useState(new DeliveryStore());

  useEffect(() => {
    const disposer = reaction(
      () => viewStore.selectedCompanyId || null,
      (companyId: number | null) => {
        deliveryStore.updateFilter({
          company: companyId,
        });
      }
    );
    deliveryStore.filter.company = viewStore.selectedCompanyId || null;
    deliveryStore.fetch();
    return disposer;
  }, [deliveryStore]);

  return (
    <Container>
      <Sidebar />
      <Body>
        <Content>
          <Header />
          <FilterBar
            filter={deliveryStore.filter}
            updateFilter={deliveryStore.updateFilter}
            onDownload={deliveryStore.download}
          />
          <Card column="span 3">
            <ImportantNumber label="Transactions">
              {deliveryStore.count}
            </ImportantNumber>
          </Card>

          <Card column="span 3">
            <ImportantNumber label="Gross Volume (in gal)">
              <NumberFormat
                value={
                  deliveryStore.totalVolume
                    ? Math.round(deliveryStore.totalVolume)
                    : undefined
                }
                displayType={"text"}
                thousandSeparator={true}
              />
            </ImportantNumber>
          </Card>

          <Card column="span 3">
            <ImportantNumber label="Net sales">
              <NumberFormat
                value={
                  deliveryStore.totalCost
                    ? Math.round(deliveryStore.totalCost)
                    : undefined
                }
                displayType={"text"}
                thousandSeparator={true}
                prefix={"$"}
              />
            </ImportantNumber>
          </Card>

          <Card column="span 12">
            <Table store={deliveryStore} />
          </Card>
        </Content>
      </Body>
    </Container>
  );
});

export default Transactions;
