import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Tab as RTab, Tabs, TabList as RTabList, TabPanel } from "react-tabs";
import { reaction } from "mobx";

import { Sidebar } from "../components/layout/Sidebar";
import { Header } from "../components/layout/Header";
import { Container, Content, Body } from "../components/ui/Fundamentals";
import FlowrateTable from "../components/graphs/DataTables/FlowrateTable";
import PressureTable from "../components/graphs/DataTables/PressureTable";
import { Card } from "../components/ui/Card";
import { RegisterWithDeliveriesStore } from "../store/Register";
import viewStore from "../store/View";
import { observer } from "mobx-react";
import { FilterBar } from "../components/filter/Bar";
import TempTable from "../components/graphs/DataTables/TempTable";

interface CardProps {
  row?: string;
  column?: string;
}
export const HiddenCard = styled.div<CardProps>`
  background: var(--dark);
  border-radius: var(--radius);
  grid-row: ${(props) => props.row};
  grid-column: ${(props) => props.column};

  > h2 {
    font-size: 1rem;
    margin-top: 0.5rem;
  }
`;

const Badge = styled.div`
  display: inline-block;
  margin-right: 15px;
  padding: 0rem;
  position: relative;
  background-color: #898989;
  color: var(--darkest);
  font-size: 0.8rem;
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 1.5rem;
  opacity: 1;
  transform: translateY(-0.3rem);
`;

const BadgeNumber = styled.div`
  position: relative;
  text-align: center;
  font-weight: bold;
  top: 50%;
  transform: translateY(-50%);
`;

const Tab = styled(RTab)`
  background: var(--dark);
  padding: 0rem;
  font-size: 1.15rem;
  border-radius: var(--radius);
  display: inline-block;
  cursor: pointer;
  outline: none;
  margin: 0;
  list-style-position:inside;
  border: 0.5px solid rgba(0,0,0,0.2);

  &:hover & {
    background: var(--darker);
  }

  > h2 {
    font-size: 1rem;
    margin-top: 0.5rem;
  }

  &.react-tabs__tab--disabled {
    color: grey
  }

  &.react-tabs__tab--selected {
    background: var(--darker);

    ${Badge} {
      background-color: var(--accent)};
    }
  }
`;

const TabList = styled(RTabList)`
  list-style: none;
  margin: 0;
  padding: 0;
`;

const TabTitle = styled.p`
  display: inline-block;
  padding: 1rem;
  margin: 0;
`;

export const Registers: React.FC = observer(() => {
  const [registerStore] = useState(new RegisterWithDeliveriesStore());

  useEffect(() => {
    const disposer = reaction(
      () => viewStore.selectedCompanyId || null,
      (companyId: number | null) => {
        registerStore.updateFilter({
          company: companyId,
        });
      }
    );
    registerStore.filter.company = viewStore.selectedCompanyId || null;
    registerStore.fetch();
    return disposer;
  }, [registerStore]);

  return (
    <Container>
      <Sidebar />
      <Body>
        <Content>
          <Header />
          <FilterBar
            filter={registerStore.filter}
            updateFilter={registerStore.updateFilter}
            onDownload={registerStore.download}
          />
          <HiddenCard column="span 12">
            <Tabs>
              <TabList>
                <Tab>
                  <TabTitle>Flowrate</TabTitle>
                  {registerStore.flowRateWarnings > 0 && (
                    <Badge>
                      <BadgeNumber>
                        {registerStore.flowRateWarnings}
                      </BadgeNumber>
                    </Badge>
                  )}
                </Tab>
                <Tab>
                  <TabTitle>Diff. pressure</TabTitle>
                </Tab>
                <Tab>
                  <TabTitle>Tank inventory</TabTitle>
                </Tab>
                <Tab>
                  <TabTitle>Water detection</TabTitle>
                </Tab>
                <Tab>
                  <TabTitle>Fuel density</TabTitle>
                </Tab>
                <Tab>
                  <TabTitle>Fuel temperature</TabTitle>
                </Tab>
              </TabList>
              <TabPanel>
                {registerStore && (
                  <FlowrateTable registers={registerStore.data} />
                )}
              </TabPanel>
              <TabPanel>
                {registerStore && (
                  <PressureTable registers={registerStore.data} />
                )}
              </TabPanel>
              <TabPanel>
                <Card>No data available</Card>
              </TabPanel>
              <TabPanel>
                <Card>No data available</Card>
              </TabPanel>
              <TabPanel>
                <Card>No data available</Card>
              </TabPanel>
              <TabPanel>
                {registerStore && <TempTable registers={registerStore.data} />}
              </TabPanel>
            </Tabs>
          </HiddenCard>
        </Content>
      </Body>
    </Container>
  );
});

export default Registers;
