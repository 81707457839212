import React, { useState } from "react";
import { observer } from "mobx-react";
import styled from "styled-components";

import { Button } from "../ui/Button";
import { Container, Content, Body } from "../ui/Fundamentals";
import viewStore from "../../store/View";
import { Container as Aside } from "../layout/Sidebar";

const ActionButton = styled(Button)`
  background: var(--accent);
  color: black;
`;

const Label = styled.label`
  display: grid;
  grid-template-rows: repeat(2, auto);
  grid-row-gap: 0.5rem;
  margin-bottom: 1rem;
`;

const Card = styled.div`
  background: var(--darker);
  padding: 1.5rem;
  grid-column: span 6;
  border-radius: var(--radius);

  > h2 {
    font-size: 1rem;
    margin-top: 0.5rem;
  }
`;

const Input = styled.input`
  background: var(--dark);
  color: inherit;
  border: none;
  outline: none;
  border-radius: var(--radius);
  padding: 0.5rem 0.5rem;
`;

const ErrorLabel = styled.span`
  margin-left: 2rem;
`;

export const Login: React.FC = observer(() => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [errorState, setErrorState] = useState(false);
  const submit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      await viewStore.performLogin(username, password);
      setErrorState(false);
    } catch (e) {
      setErrorState(true);
    }
  };
  return (
    <Container>
      <Aside>
        <span>
          <img src="lc-logo-tiny.png" alt="Liquid controls logo" />
        </span>
      </Aside>
      <Body>
        <Content>
          <Card>
            <form onSubmit={submit}>
              <h2>FuelIQ Cloud</h2>
              <Label>
                Username
                <Input
                  type="text"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                />
              </Label>
              <Label>
                Password
                <Input
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </Label>
              <ActionButton type="submit">Submit</ActionButton>
              {errorState && <ErrorLabel>Login failed</ErrorLabel>}
            </form>
          </Card>
        </Content>
      </Body>
    </Container>
  );
});
