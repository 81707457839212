import DataTable, { createTheme } from "react-data-table-component";
import React from "react";
import { AggregatedRegister } from "../../../store/Register";

createTheme("fuelIQ", {
  text: {
    primary: "#FFFFFF",
    secondary: "rgba(255, 255, 255, 0.7)",
    disabled: "rgba(0,0,0,.12)",
  },
  background: {
    default: "var(--darker)",
  },
  context: {
    background: "#E91E63",
    text: "#FFFFFF",
  },
  divider: {
    default: "rgba(81, 81, 81, 1)",
  },
  button: {
    default: "#FFFFFF",
    focus: "rgba(255, 255, 255, .54)",
    hover: "rgba(255, 255, 255, .12)",
    disabled: "rgba(0,0,0,.12)",
  },
  sortFocus: {
    default: "rgba(255, 255, 255, .54)",
  },
  selected: {
    default: "rgba(0, 0, 0, .7)",
    text: "#FFFFFF",
  },
  highlightOnHover: {
    default: "rgba(0, 0, 0, .7)",
    text: "#FFFFFF",
  },
  striped: {
    default: "rgba(0, 0, 0, .87)",
    text: "#FFFFFF",
  },
});

const customStyles = {
  headCells: {
    style: {
      fontWeight: 550,
      fontSize: 14,
    },
  },
  rows: {
    style: {
      fontSize: 14,
    },
  },
  pagination: {
    style: {
      color: "var(--text)",
    },
  },
};

const conditionalRowStyles = [
  {
    when: (cell: any) => parseFloat(cell.flowRateChange) > 30,
    style: {
      color: "var(--accent)",
    },
  },
];

const columns = [
  {
    name: "Register ID",
    selector: "register",
  },
  {
    name: "Max. corrected dP (PSI)",
    selector: "maxPressure",
    format: (row: any) => Math.round(row.maxPressure * 100) / 100,
  },
  {
    name: "Max. dP flowrate (gal/min)",
    selector: "maxCorrectedPressure",
    format: (row: any) => Math.round(row.maxCorrectedPressure * 100) / 100,
  },
  {
    name: "Avg. corrected dP (PSI)",
    selector: "avgPressure",
    format: (row: any) => Math.round(row.avgPressure * 100) / 100,
  },
  {
    name: "Avg. actual dP (PSI)",
    selector: "avgCorrectedPressure",
    format: (row: any) => Math.round(row.avgCorrectedPressure * 100) / 100,
  },
  {
    name: "Change",
    selector: "flowRateChange",
    conditionalCellStyles: conditionalRowStyles,
    sortable: true,
    format: (row: any) => Math.round(row.flowRateChange * 100) / 100,
  },
];

interface TableProps {
  registers: AggregatedRegister[];
}

export default class PressureTable extends React.Component<TableProps> {
  render() {
    return (
      <DataTable
        noHeader
        columns={columns}
        data={this.props.registers}
        customStyles={customStyles}
        pagination={true}
        theme="fuelIQ"
        paginationPerPage={25}
      />
    );
  }
}
