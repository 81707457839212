import React from "react";
import RSelect, { ValueType } from "react-select";

const customStyles = {
  control: (base: any, state: any) => {
    return {
      ...base,
      background: "var(--darker)",
      border: "none !important",
      boxShadow: "none",
      opacity: state.isDisabled ? 0.4 : null,
    };
  },
  container: (base: any) => ({
    ...base,
    minWidth: "180px",
  }),
  option: (base: any, state: any) => {
    let background = "transparent";

    if (state.isFocused) {
      background = "var(--dark)";
    }
    if (state.isSelected) {
      background = "var(--focus)";
    }
    return {
      ...base,
      cursor: "pointer",
      backgroundColor: `${background} !important`,
    };
  },
  menu: (base: any) => ({
    ...base,
    borderRadius: 0,
    marginTop: 0,
    zIndex: 5,
    background: "var(--darker)",
  }),
  menuList: (base: any) => ({
    ...base,
    overflowX: "hidden",
    padding: 0,
    color: "var(--text)",
  }),
  singleValue: () => ({
    color: "var(--text)",
  }),
  input: (base: any) => ({
    ...base,
    color: "var(--dark-text)",
  }),
  placeholder: () => ({
    fontSize: "1rem",
    color: "var(--dark-text)",
  }),
  indicatorSeparator: (base: any) => ({
    ...base,
    background: "var(--focus)",
  }),
  valueContainer: (base: any) => ({
    ...base,
    padding: "0.5rem",
  }),
};

type Value = string | number;

interface OptionProps {
  label: string;
  value: Value;
}

interface FilterProps {
  placeholder: string;
  disabled?: boolean;
  value: Value | null;
  onChange?: (value: any) => void;
  options: OptionProps[];
}

export const Select: React.FC<FilterProps> = (props) => {
  const { placeholder, options, disabled, value, onChange } = props;

  const handleChange = (option: ValueType<OptionProps>) => {
    if (!onChange) {
      return;
    }
    if (option === null) {
      onChange(option);
    } else {
      const aaaa = option as any; // How does this type work?
      onChange(aaaa.value);
    }
  };
  return (
    <RSelect
      placeholder={placeholder}
      isClearable
      options={options}
      isDisabled={disabled}
      styles={customStyles}
      value={options.find((o) => o.value === value)}
      onChange={handleChange}
    />
  );
};
