import React from "react";
import styled from "styled-components";
import { observer } from "mobx-react";

import { Settings } from "../auth/Settings";
import viewStore from "../../store/View";
import { Select } from "../ui/Select";

const Container = styled.div`
  display: flex;
  grid-row-span: 1;
  justify-content: space-between;
  grid-column: 1 / -1;
`;

const Title = styled.div`
  display: flex;

  h2 {
    font-size: 29px;
    margin: 0;
    margin-right: 1rem;
  }
`;

export const Header: React.FC = observer(() => {
  const selectCompany = (companyId: number) => {
    viewStore.selectedCompanyId = companyId;
  };
  return (
    <Container>
      <Title>
        <h2>
          FUELiQ -{" "}
          {!viewStore.currentUser.isSuperadmin && viewStore.selectedCompanyName}
        </h2>
        {viewStore.currentUser.isSuperadmin && (
          <Select
            placeholder="All companies"
            value={viewStore.selectedCompanyId || null}
            onChange={selectCompany}
            options={viewStore.companies.toOptions}
          />
        )}
      </Title>
      <Settings />
    </Container>
  );
});
