import React from "react";
import { observer } from "mobx-react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import { Login } from "./auth/Login";
import { Home } from "../screens/Home";
import viewStore from "../store/View";
import Transactions from "../screens/Transactions";
import Registers from "../screens/Registers";

export const App: React.FC = observer(() => {
  if (!viewStore.bootstrapped) {
    return null;
  }
  if (!viewStore.isAuthenticated) {
    return <Login />;
  }
  return (
    <Router>
      <Switch>
        <Route path="/" exact>
          <Home />
        </Route>
        <Route path="/home" exact>
          <Home />
        </Route>
        <Route path="/registers" exact>
          <Registers />
        </Route>
        <Route path="/transactions">
          <Transactions />
        </Route>
      </Switch>
    </Router>
  );
});
