import { Filter, api } from "./Base";
import { AxiosResponse } from "axios";
import { observable, action } from "mobx";
import moment from "moment";
import download from "downloadjs";

export const COLORS = [
  "#7F3C8D",
  "#11A579",
  "#3969AC",
  "#F2B701",
  "#E73F74",
  "#80BA5A",
  "#E68310",
  "#008695",
  "#CF1C90",
  "#f97b72",
  "#4b4b8f",
  "#A5AA99",
];

export const PRODUCT_TYPES = [
  "Aviation",
  "Gasoline",
  "None",
  "LPG",
  "Distillate",
  "Lube Oil",
];

export const PRODUCT_OPTIONS = PRODUCT_TYPES.map((label, i) => ({
  value: i,
  label: label || "Null",
}));

export const getColorForProductType = (productType: string) => {
  let index = PRODUCT_TYPES.findIndex((type) => type === productType);

  if (index === -1) {
    index += PRODUCT_TYPES.length;
  }

  return COLORS[index];
};

interface Delivery {
  productType: string;
  totalCost: string;
  grossQty: string;
  locationLat: string;
  locationLong: string;
  salesNumber: string;
  createdAt: string;
  user: number;
  register: number;
  avgFlowrate: string;
}

export interface OptionalFilter {
  fueler: number | null;
  register: number | null;
  productType: string | null;
  company: number | null;
  startDate: moment.Moment | null;
  endDate: moment.Moment | null;
}

export class DeliveryStore {
  @observable filter: OptionalFilter = {
    company: null,
    fueler: null,
    register: null,
    productType: null,
    startDate: null,
    endDate: null,
  };

  orderBy?: string = "-createdAt";

  @observable data: Delivery[] = [];
  @observable page = 1;
  @observable count = 0;
  @observable totalCost = 0;
  @observable totalVolume = 0;

  get queryParams(): any {
    return {
      user__profile__company: this.filter.company,
      user: this.filter.fueler,
      register: this.filter.register,
      createdAt__gte: this.filter.startDate?.toISOString(),
      createdAt__lte: this.filter.endDate?.toISOString(),
      productType: this.filter.productType,
    };
  }

  updateFilter = (filterPatch: Partial<Filter>) => {
    this.filter = {
      ...this.filter,
      ...filterPatch,
    };

    this.fetch();
  };

  download = async () => {
    const response = await api.get("/delivery/csv/", {
      params: this.queryParams,
    });
    download(response.data, `deliveries.csv`);
  };

  @action async fetch() {
    const params = this.queryParams;

    if (this.page !== undefined) {
      params.page = this.page;
    }

    if (this.orderBy !== undefined) {
      params.orderBy = this.orderBy;
    }

    const response: AxiosResponse<any> = await api.get("/delivery/all/", {
      params,
    });
    this.data = response.data.data;
    this.count = response.data.count;
    this.totalCost = response.data.netSales;
    this.totalVolume = response.data.grossVolume;
  }
}
