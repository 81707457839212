import styled from "styled-components";

export const Container = styled.div`
  display: grid;
  height: 100vh;
  width: 100%;
  grid-template-columns: 100px 1fr;
`;

export const Content = styled.div`
  display: grid;
  padding: 2rem;
  grid-template-columns: repeat(12, 1fr);
  grid-gap: 2rem;
  max-width: 1200px;
  margin: auto;
`;

export const Body = styled.div`
  padding: 2rem;
  overflow-y: scroll;
`;
