import React from 'react';
import styled from 'styled-components';

const Row = styled.div``;

const ImportantNumber = styled.p`
  font-size: 2rem;
  margin: 1rem 0;
  font-weight: bold;
  color: var(--accent);
`;

interface NumberProps {
    label: string;
  }
  
export const Number: React.FC<NumberProps> = props => {
return (
    <Row>
    <p>{props.label}</p>
    <ImportantNumber>{props.children}</ImportantNumber>
    </Row>
);
};