import { AxiosResponse } from "axios";
import moment from "moment";
import { observable, reaction, action } from "mobx";
import download from "downloadjs";
import { groupBy } from "lodash";

import { api, Filter } from "./Base";
import viewStore from "./View";

interface CompanyView {
  transactions: number;
  grossVolume: number;
  netSales: number;
}

export interface DeliveryMapMarker {
  id: number;
  productType: string;
  totalCost: string;
  grossQty: string;
  netQty: string;
  locationLat: string;
  locationLong: string;
  salesNumber: string;
  createdAt: string;
  user__profile__company__name: string;
  user: number;
  register: number;
  avgFlowrate: string;
}

export interface Delivery {
  createdAt__date: string;
  productType: string;
  grossQty: string;
  netQty: string;
  date: number;
}

export interface GroupedDeliveries {
  [key: string]: Delivery[];
}

export class SummaryStore {
  @observable filter: Filter = {
    company: null,
    fueler: null,
    register: null,
    productType: null,
    startDate: moment().subtract(14, "days"),
    endDate: moment(),
  };
  @observable company?: CompanyView;
  @observable deliveries?: DeliveryMapMarker[];
  @observable summaryByDay?: GroupedDeliveries;

  constructor() {
    reaction(
      () => viewStore.selectedCompanyId,
      (companyId) => {
        this.updateFilter({ company: companyId });
      }
    );
  }

  get queryParams(): any {
    return {
      user__profile__company: this.filter.company,
      user: this.filter.fueler,
      register: this.filter.register,
      createdAt__gte: this.filter.startDate?.toISOString(),
      createdAt__lte: this.filter.endDate?.toISOString(),
      productType: this.filter.productType,
    };
  }

  updateFilter = (filterPatch: Partial<Filter>) => {
    this.filter = {
      ...this.filter,
      ...filterPatch,
    };

    this.fetch();
  };

  download = () => {
    download(JSON.stringify(this.deliveries), `deliverySummary.json`);
  };

  @action async fetch() {
    const params = this.queryParams;
    const reponseCompanySummary = await api.get("/company/summary/", {
      params,
    });
    const reponseAllDeliveries: AxiosResponse<any> = await api.get(
      "/delivery/location/",
      {
        params,
      }
    );
    const reponseSummaryByDay: AxiosResponse<any> = await api.get(
      "/delivery/summary_by_day/",
      {
        params,
      }
    );

    this.company = reponseCompanySummary.data;
    this.deliveries = reponseAllDeliveries.data.data;
    this.summaryByDay = this.parseGroupedeliveries(reponseSummaryByDay.data);
  }

  parseGroupedeliveries(data: any) {
    const formattedData = data.data.map((del: any) => ({
      date: new Date(del.createdAt__date).getTime(),
      grossQty: Number(del.grossQty),
      count: Number(del.count),
      netQty: Number(del.netQty),
      productType: del.productType,
    }));
    return groupBy(formattedData, (group) => group.productType) as any;
  }
}

const summaryStore = new SummaryStore();
export default summaryStore;
