import React from "react";
import styled from "styled-components";
import { NavLink } from "react-router-dom";

export const Container = styled.aside`
  background: var(--darkest);
  width: 6rem;
  height: 100%;
  padding: 1rem 0;

  > span {
    margin-bottom: 1rem;
  }

  display: flex;
  flex-direction: column;
  align-items: center;
`;

interface PageProps {
  active?: boolean;
}
const Page = styled(NavLink)<PageProps>`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-decoration: none;

  height: 4.5rem;
  border-left: 3px solid transparent;
  cursor: pointer;
  font-size: 0.75rem;

  > i {
    font-size: 2rem;
  }

  &:hover {
    background: var(--darker);
  }

  &.active {
    background: var(--darker);
    font-size: bold;
    border-color: var(--accent);
  }
`;

export const Sidebar: React.FC = () => {
  return (
    <Container>
      <span>
        <img src="lc-logo-tiny.png" alt="Liquid controls logo" />
      </span>
      <Page activeClassName="active" exact to="/">
        <i className="material-icons">dashboard</i>
        Dashboard
      </Page>
      <Page activeClassName="active" to="/registers">
        <i className="material-icons">keyboard</i>
        Registers
      </Page>
      <Page activeClassName="active" to="/transactions">
        <i className="material-icons">reorder</i>
        Transactions
      </Page>
    </Container>
  );
};
