import { observable, computed } from "mobx";
import { Model, Store, SelectOption, api } from "./Base";

export class Company extends Model {
  @observable industry = "";
  @observable name = "";
  @observable country = "";
  @observable aviationOptions = false;
}

export class CompanyStore extends Store<Company> {
  Model = Company;
  target = "company";

  @computed get toOptions(): SelectOption[] {
    return this.models.map((model) => ({
      value: model.id,
      label: `${model.id} | ${model.name}`,
    }));
  }

  async fetch() {
    const response = await api.get(`${this.target}/`, {
      params: {
        deliveries__count__gte: 0,
      },
    });
    this.parse(response.data);
  }
}
