import styled from "styled-components";

interface CardProps {
  row?: string;
  column?: string;
}
export const Card = styled.div<CardProps>`
  background: var(--darker);
  padding: 1.5rem;
  border-radius: var(--radius);
  grid-row: ${props => props.row};
  grid-column: ${props => props.column};

  > h2 {
    font-size: 1rem;
    margin-top: 0.5rem;
  }
`;