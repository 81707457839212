import React from "react";
import styled from "styled-components";

import viewStore from "../../store/View";

const Container = styled.div`
  position: relative;
  display: inline-block;

  > i {
    cursor: pointer;
    font-size: 1.5rem;
  }

  > span {
    display: flex;
    margin-top: 11px;
    height: 1.75rem;
  }

  &:hover > ul {
    display: block;
  }
`;

const Dropdown = styled.ul`
  display: none;
  position: absolute;
  background: var(--darker);

  margin: 0;
  padding: 0;

  right: 0;
  z-index: 1;
  min-width: 100px;
  list-style: none;
`;

const ListItem = styled.li`
  width: 100%;
  cursor: pointer;
  padding: 0.5rem 0.75rem;

  &:hover {
    background: rgba(255, 255, 255, 0.1);
  }
`;

export const Settings: React.FC = () => {
  const logout = () => {
    viewStore.performLogout();
  };
  return (
    <Container>
      <span>
        {viewStore.currentUser.username}{" "}
        <i className="material-icons">arrow_drop_down</i>
      </span>
      <Dropdown>
        <ListItem onClick={logout}>Log out</ListItem>
      </Dropdown>
    </Container>
  );
};
