import DataTable, { createTheme } from "react-data-table-component";
import React from "react";
import moment from "moment";

import { DeliveryStore } from "../../../store/Delivery";
import { observer } from "mobx-react";

createTheme("fuelIQ", {
  text: {
    primary: "#FFFFFF",
    secondary: "rgba(255, 255, 255, 0.7)",
    disabled: "rgba(0,0,0,.12)",
  },
  background: {
    default: "var(--darker)",
  },
  context: {
    background: "#E91E63",
    text: "#FFFFFF",
  },
  divider: {
    default: "rgba(81, 81, 81, 1)",
  },
  button: {
    default: "#FFFFFF",
    focus: "rgba(255, 255, 255, .54)",
    hover: "rgba(255, 255, 255, .12)",
    disabled: "rgba(0,0,0,.12)",
  },
  sortFocus: {
    default: "rgba(255, 255, 255, .54)",
  },
  selected: {
    default: "rgba(0, 0, 0, .7)",
    text: "#FFFFFF",
  },
  highlightOnHover: {
    default: "rgba(0, 0, 0, .7)",
    text: "#FFFFFF",
  },
  striped: {
    default: "rgba(0, 0, 0, .87)",
    text: "#FFFFFF",
  },
});

const customStyles = {
  headCells: {
    style: {
      fontWeight: 550,
      fontSize: 14,
    },
  },
  rows: {
    style: {
      fontSize: 14,
    },
  },
  pagination: {
    style: {
      color: "var(--text)",
    },
  },
};

const columns = [
  {
    name: "Sales number",
    selector: "saleNumber",
    sortable: true,
  },
  {
    name: "Date and time",
    selector: "createdAt",
    cell: (row: Delivery) =>
      moment(row.createdAt).format("MM/DD/YYYY HH:mm:ss"),
    grow: 2,
    sortable: true,
  },
  {
    name: "Register ID",
    selector: "register",
    sortable: true,
  },
  {
    name: "Fueler ID",
    selector: "user",
    sortable: true,
  },
  {
    name: "Product",
    selector: "productType",
    sortable: true,
  },
  {
    name: "Gross volume (gal)",
    selector: "grossQty",
    sortable: true,
  },
  {
    name: "Avg flowrate (gal/min)",
    selector: "avgFlowRate",
    sortable: true,
  },
];

interface TableProps {
  store: DeliveryStore;
}

interface Delivery {
  productType: string;
  totalCost: string;
  grossQty: string;
  locationLat: string;
  locationLong: string;
  salesNumber: string;
  createdAt: string;
  user: number;
  register: number;
  avgFlowrate: string;
}

const PAGE_SIZE = 25;
@observer
export default class Table extends React.Component<TableProps> {
  handleSort = (column: any, direction: "asc" | "desc") => {
    const { store } = this.props;
    store.orderBy = `${direction === "desc" ? "-" : ""}${column.selector}`;
    store.fetch();
  };
  handleChangePage = (page: number, rows: number) => {
    const { store } = this.props;
    store.page = page;
    store.fetch();
  };
  render() {
    const { store } = this.props;
    return (
      <DataTable
        noHeader
        onSort={this.handleSort}
        sortServer
        paginationServer
        onChangePage={this.handleChangePage}
        columns={columns}
        customStyles={customStyles}
        data={store.data}
        defaultSortField="createdAt"
        defaultSortAsc={false}
        pagination
        theme="fuelIQ"
        paginationPerPage={25}
        paginationTotalRows={store.count}
        paginationRowsPerPageOptions={[PAGE_SIZE]}
      />
    );
  }
}
