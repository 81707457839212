import React from "react";
import { VictoryLine, VictoryChart, VictoryAxis } from "victory";
import moment from "moment";

interface Delivery {
  datetime: string;
  avgFlowRate: string;
}

interface ChartPorps {
  deliveries: Delivery[];
}

export default class DeliveriesByRegisterChart extends React.Component<
  ChartPorps
> {
  render() {
    return (
      <VictoryChart
        width={800}
        height={180}
        padding={{ top: 15, bottom: 25, left: 60, right: 30 }}
      >
        <VictoryLine
          style={{
            data: { stroke: "var(--accent)", strokeWidth: "1px" },
          }}
          data={this.props.deliveries.map((delivery) => {
            return {
              x: new Date(delivery.datetime),
              y: Number(delivery.avgFlowRate),
            };
          })}
        />
        <VictoryAxis
          dependentAxis
          width={800}
          height={180}
          style={{
            tickLabels: { fill: "white", fontSize: 10 },
            grid: { stroke: "#818e99", strokeWidth: 1 },
          }}
        />

        <VictoryAxis
          width={800}
          height={280}
          tickFormat={(t) => {
            let date = new Date(t);
            return moment(date).format("MMM DD");
          }}
          style={{
            tickLabels: { fill: "white", fontSize: 10 },
            axis: {
              fill: "transparent",
              stroke: "#FFFFFF",
              strokeWidth: 1,
            },
          }}
        />
      </VictoryChart>
    );
  }
}
