import { Filter, api, Store, Model, SelectOption } from "./Base";
import { AxiosResponse } from "axios";
import { observable, action, computed } from "mobx";
import moment from "moment";
import download from "downloadjs";
import { OptionalFilter } from "./Delivery";

export class Register extends Model {
  @observable name = "";
}

export class RegisterStore extends Store<Register> {
  Model = Register;
  target = "register";

  async fetch() {
    const response = await api.get(`${this.target}/`, {
      params: {
        deliveries__count__gte: 0,
      },
    });
    this.parse(response.data);
  }

  @computed get toOptions(): SelectOption[] {
    return this.models.map((model) => ({
      value: model.id,
      label: `${model.id} | ${model.name}`,
    }));
  }
}

export interface AggregatedRegister {
  register: number;
  numTransactions: number;
  maxFlowrate: string;
  avgFlowrate: string;
  maxCorrectedPressure: string;
  maxPressure: string;
  avgCorrectedPressure: string;
  avgPressure: string;
  avgTemperature: string;
}

export class RegisterWithDeliveriesStore {
  @observable filter: OptionalFilter = {
    company: null,
    fueler: null,
    register: null,
    productType: null,
    startDate: moment().subtract(90, "days"),
    endDate: moment(),
  };

  @observable data: AggregatedRegister[] = [];
  @observable flowRateWarnings: number = 0;

  get queryParams(): any {
    return {
      user__profile__company: this.filter.company,
      user: this.filter.fueler,
      register: this.filter.register,
      createdAt__gte: this.filter.startDate?.toISOString(),
      createdAt__lte: this.filter.endDate?.toISOString(),
      productType: this.filter.productType,
    };
  }

  updateFilter = (filterPatch: Partial<Filter>) => {
    this.filter = {
      ...this.filter,
      ...filterPatch,
    };

    this.fetch();
  };

  download = () => {
    download(JSON.stringify(this.data), `registers.json`);
  };

  @action async fetch() {
    const params = this.queryParams;
    const response: AxiosResponse<any> = await api.get(
      "/delivery/byregister/",
      {
        params,
      }
    );
    this.data = response.data.registers;
    this.flowRateWarnings = response.data.flowRateWarnings;
  }
}
