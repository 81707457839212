import React, { useState } from "react";
import {
  VictoryBar,
  VictoryChart,
  VictoryAxis,
  VictoryStack,
  VictoryGroup,
  VictoryLegend,
  VictoryTooltip,
} from "victory";
import styled from "styled-components";

import { GroupedDeliveries } from "../../../store/Summary";
import { getColorForProductType } from "../../../store/Delivery";
import { observer } from "mobx-react";
import { map } from "lodash";
import moment from "moment";

const SelectButtons = styled.div`
  display: inline;
`;

const Label = styled.label`
  display: inline-block;
  position: relative;
  padding-left: 30px;
  margin-left: 20px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`;

const RadioButton = styled.input`
  position: absolute;
  opacity: 0;
  cursor: pointer;
`;

const CustomRadioButton = styled.span<RadioProps>`
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  padding-left: 10px;
  background-color: var(--darker);
  border-radius: 50%;
  border: 2px solid var(--text);

  ${(props) =>
    props.selected &&
    `
    &:after {
      content: "";
      position: absolute;
      display: block;
      top: 3px;
      left: 3px;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background: var(--text);
    }
  `}
`;

interface RadioProps {
  selected: boolean;
}
interface ChartProps {
  data: GroupedDeliveries;
}

export const StackedBarChart: React.FC<ChartProps> = observer((props) => {
  const { data } = props;
  const [volume, setVolume] = useState("grossQty");

  const chartWidth = 800;

  const handleVolumeSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    setVolume(event.target.value);
  };

  const productTypes = Object.keys(data);
  const colorScheme = productTypes.map(getColorForProductType);

  const dates = map(data, (val, productType) => {
    return val.map((v) => v.date);
  }).flat();

  const lastDate = Math.max(...dates);
  const firstDate = Math.min(...dates);
  const displayedNumberOfDays = (lastDate - firstDate) / (1000 * 3600 * 24) + 1;
  const maxNumberOfDays = 90;

  return (
    <div>
      <SelectButtons onChange={handleVolumeSelect}>
        Volume (gal)
        <Label htmlFor="grossQty">
          Gross
          <RadioButton
            name="qty"
            type="radio"
            value="grossQty"
            id="grossQty"
            defaultChecked
          />
          <CustomRadioButton selected={volume === "grossQty"} />
        </Label>
        <RadioButton type="radio" value="netQty" id="netQty" name="qty" />
        <Label htmlFor="netQty">
          Net
          <CustomRadioButton selected={volume === "netQty"} />
        </Label>
      </SelectButtons>

      <VictoryChart
        domainPadding={{ x: chartWidth / displayedNumberOfDays / 2 + 10 }}
        width={chartWidth}
        height={280}
      >
        <VictoryGroup width={chartWidth} height={180}>
          <VictoryStack colorScale={colorScheme}>
            {map(data, (val, productType) => (
              <VictoryBar
                key={productType}
                barWidth={Math.max(
                  300 / displayedNumberOfDays,
                  chartWidth / maxNumberOfDays
                )}
                data={val}
                alignment="start"
                labels={({ datum }) =>
                  `${moment(datum.date).format("MMM DD")} \n ${
                    datum.count
                  } deliveries \n ${datum[volume]} gallon`
                }
                labelComponent={<VictoryTooltip />}
                x="date"
                y={volume}
              />
            ))}
          </VictoryStack>
        </VictoryGroup>
        <VictoryAxis
          dependentAxis
          width={chartWidth}
          height={280}
          style={{
            tickLabels: { fill: "white", fontSize: 10 },
            grid: { stroke: "#818e99", strokeWidth: 1 },
          }}
        />
        <VictoryAxis
          width={chartWidth}
          height={280}
          tickFormat={(t) => moment(t).format("MMM-DD")}
          style={{
            tickLabels: { fill: "white", fontSize: 10 },
            axis: {
              fill: "transparent",
              stroke: "#FFFFFF",
              strokeWidth: 1,
            },
          }}
        />
      </VictoryChart>
      <VictoryLegend
        x={20}
        y={0}
        height={20}
        orientation="horizontal"
        borderPadding={0}
        gutter={20}
        style={{ labels: { fill: "white", fontSize: 8 } }}
        data={productTypes.map((type, index) => {
          return {
            name: type,
            symbol: { fill: colorScheme[index] },
          };
        })}
      />
    </div>
  );
});
