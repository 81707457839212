import React, { useState, Fragment } from "react";
import styled from "styled-components";
import { observer } from "mobx-react";

import { Button } from "../ui/Button";
import { Select } from "../ui/Select";
import { DateRangeFilter, DateRange } from "./DateRange";
import { Filter } from "../../store/Base";
import { PRODUCT_OPTIONS, PRODUCT_TYPES } from "../../store/Delivery";
import viewStore from "../../store/View";

export const Container = styled.div`
  grid-column: 1 / -1;
  display: flex;

  > button {
    height: 46px;
    margin-right: 1rem;
    transition: background 0.1s ease-in-out;

    &.active {
      background: var(--focus);
    }
  }

  > div {
    margin-right: 1rem;
  }
`;

export const DownloadButton = styled(Button)`
  margin: 0;
  margin-left: auto;
  margin-right: 0 !important;
  display: flex;

  > i {
    width: 1rem;
    margin-right: 1rem;
  }
`;

interface Props {
  filter: Filter;
  onDownload?: () => void;
  limitRange?: boolean;
  updateFilter(filter: Partial<Filter>): void;
}

export const FilterBar: React.FC<Props> = observer((props) => {
  const { filter, updateFilter, onDownload, limitRange } = props;
  const [open, showFilter] = useState(false);

  const toggleFilters = () => {
    showFilter(!open);
  };

  const handleRangeChange = (range: DateRange) => {
    updateFilter({
      startDate: range.startDate || undefined,
      endDate: range.endDate || undefined,
    });
  };

  const handleProductTypeChange = (productId: number) => {
    updateFilter({
      productType: PRODUCT_TYPES[productId],
    });
  };

  return (
    <Fragment>
      <Container>
        <Button onClick={toggleFilters} className={open ? "active" : ""}>
          <i className="material-icons">tune</i>
        </Button>
        <DateRangeFilter
          startDate={filter.startDate}
          endDate={filter.endDate}
          limitRange={limitRange || false}
          onChange={handleRangeChange}
        />
        <DownloadButton onClick={onDownload}>
          <i className="material-icons">file_download</i>
          Download
        </DownloadButton>
      </Container>
      {open && (
        <Container>
          <Select
            placeholder="Select fueler"
            value={filter.fueler}
            onChange={(fueler) => {
              updateFilter({ fueler });
            }}
            options={viewStore.fuelers.toOptions}
          />
          <Select
            placeholder="Select register"
            value={filter.register}
            onChange={(register) => {
              updateFilter({ register });
            }}
            options={viewStore.registers.toOptions}
          />
          <Select
            placeholder="All products"
            value={null}
            options={PRODUCT_OPTIONS}
            onChange={handleProductTypeChange}
          />
        </Container>
      )}
    </Fragment>
  );
});
