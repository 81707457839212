import { observable, computed } from "mobx";
import { Model, Store, api, SelectOption } from "./Base";

export class User extends Model {
  @observable username = "";
  @observable last_name = "";
  @observable email = "";
  @observable isSuperadmin = false;
}

export class UserStore extends Store<User> {
  Model = User;
  target = "user";

  async fetch() {
    const response = await api.get(`${this.target}/`, {
      params: {
        profile__deliveries__count__gte: 0,
      },
    });
    this.parse(response.data);
  }

  @computed get toOptions(): SelectOption[] {
    return this.models.map((model) => ({
      value: model.id,
      label: `${model.id} | ${model.last_name}`,
    }));
  }
}
