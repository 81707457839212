import * as React from "react";
import { Map, TileLayer, Marker, Popup } from "react-leaflet";
import L from "leaflet";
import MarkerClusterGroup from "react-leaflet-markercluster";

import "leaflet/dist/leaflet.css";
import "./maps.css";
import { DeliveryMapMarker } from "../../../store/Summary";
import { observer } from "mobx-react";
import { getColorForProductType } from "../../../store/Delivery";
import viewStore from "../../../store/View";
import moment from "moment";

const mapStyle = { height: "100%" };

const markerIcon = (delivery: DeliveryMapMarker) => {
  const icon = L.divIcon({
    html: `  <svg height="10" width="10">
              <circle cx="5" cy="5" r="5" fill="${getColorForProductType(
                delivery.productType
              )}" />
            </svg>`,
    className: "markerClusterChild",
  });
  return icon;
};

const createClusterIcon = (cluster: any) => {
  const count = cluster.getChildCount();
  const icon = L.divIcon({
    html: `<div class="markerClusterCircle">
          <span class="markerClusterLabel">${count}</span>
        </div>`,
    className: `markerCluster`,
  });
  return icon;
};

// Set leaflet default settings
// @ts-ignore
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});

interface Props {
  data: DeliveryMapMarker[];
}

export const Maps: React.FC<Props> = observer((props) => {
  const { data } = props;

  return (
    <Map
      className="map"
      style={mapStyle}
      center={[41.881832, -87.623177]}
      zoom={6}
      attributionControl
      zoomControl
      doubleClickZoom
      scrollWheelZoom
      dragging
      animate
      easeLinearity={0.35}
    >
      <TileLayer
        attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        url="http://{s}.basemaps.cartocdn.com/dark_all/{z}/{x}/{y}.png"
      />

      <MarkerClusterGroup
        showCoverageOnHover={false}
        maxClusterRadius={(zoom: number) => {
          if (zoom > 10) {
            return 2;
          }
          return 50;
        }}
        spiderfyDistanceMultiplier={0.6}
        iconCreateFunction={createClusterIcon}
      >
        {data.map((delivery) => (
          <Marker
            key={delivery.id}
            icon={markerIcon(delivery)}
            position={[
              Number(delivery.locationLat),
              Number(delivery.locationLong),
            ]}
          >
            <Popup>
              {viewStore.currentUser.isSuperadmin && (
                <>
                  Company : {delivery.user__profile__company__name}
                  <br />
                </>
              )}
              Driver :{" "}
              {viewStore.fuelers.get(delivery.user)?.last_name || delivery.user}
              <br />
              Fueltype : {delivery.productType}
              <br />
              Gross volume : {delivery.grossQty}
              <br />
              Net volume : {delivery.netQty}
              <br />
              Total cost: $ {delivery.totalCost}
              <br />
              Timestamp:{" "}
              {moment(delivery.createdAt).format("MM/DD/YYYY HH:mm:ss")}
            </Popup>
          </Marker>
        ))}
      </MarkerClusterGroup>
    </Map>
  );
});
