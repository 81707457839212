import { observable, computed, action } from "mobx";
import { AxiosResponse } from "axios";

import { api } from "./Base";
import { User, UserStore } from "./User";
import { CompanyStore } from "./Company";
import { RegisterStore } from "./Register";

interface BootstrapResponse {
  csrf_token: string;
  company?: any;
  user?: any;
}

export class ViewStore {
  @observable currentUser = new User();
  @observable bootstrapped = false;
  @observable companies = new CompanyStore();
  @observable fuelers = new UserStore();
  @observable registers = new RegisterStore();

  @observable selectedCompanyId?: number;
  @observable selectedCompanyName?: string;

  @computed
  get isAuthenticated() {
    return !!this.currentUser.id;
  }

  constructor() {
    this.fetchBootstrap();
  }

  @action.bound
  async fetchBootstrap() {
    const res = (await api.get("/bootstrap/")) as AxiosResponse<
      BootstrapResponse
    >;

    api.csrfToken = res.data.csrf_token;

    if (res.data.user) {
      this.currentUser.id = res.data.user.id;
      this.currentUser.username = res.data.user.username;
      this.currentUser.email = res.data.user.email;
      this.currentUser.isSuperadmin = res.data.user.is_superuser;

      if (res.data.company) {
        this.selectedCompanyName = res.data.company.name;
      }
      // get company
      this.bootstrapped = true;
      this.companies.fetch();
      this.registers.fetch();
      this.fuelers.fetch();
      return;
    }

    this.bootstrapped = true;
    return;
  }

  @action.bound
  selectCompany(id?: number, name?: string) {
    this.selectedCompanyId = id;
  }

  @action.bound
  performLogin(username: string, password: string) {
    return api
      .post("/login/", {
        username,
        password,
      })
      .then(() => this.fetchBootstrap());
  }

  @action.bound
  performLogout() {
    return api.post("/logout/").then(() => this.fetchBootstrap());
  }
}

const viewStore = new ViewStore();
export default viewStore;
