import React from "react";
import moment from "moment";
import styled from "styled-components";
import { DateRangePicker } from "react-dates";
import "react-dates/initialize"; // How terrible can you make a package?
import "react-dates/lib/css/_datepicker.css";

interface WrapperProps {
  maxRangeWarning?: boolean;
}
const Wrapper = styled.div<WrapperProps>`
  .DateRangePicker_picker {
    z-index: 500;
  }

  .DateRangePickerInput {
    background: var(--darker);
    border: none;
    color: var(--text);
    border-radius: var(--radius);
  }

  .DateInput {
    background: none;
  }

  .DateRangePickerInput_clearDates_default:focus,
  .DateRangePickerInput_clearDates_default:hover {
    background: none;
  }

  .DateInput_input {
    color: inherit;
    font-size: inherit;
    font-weight: inherit;
    background: none;
  }

  .DateInput_input__focused {
    border-bottom-color: var(--accent);
  }

  .DateInput_fangShape {
    fill: var(--darker);
  }
  .DateInput_fangStroke {
    stroke: var(--darker);
  }

  .DayPicker {
    background: var(--darker);
    color: var(--text);
  }

  .CalendarMonth,
  .CalendarMonthGrid {
    background: transparent;
  }

  ${(props) =>
    props.maxRangeWarning &&
    `
    .CalendarMonthGrid_month__horizontal:nth-child(3):after {
      position: absolute;
      margin-left: 10.4rem;
      margin-top: 4px;
      text-align: right;
      font-size: 10px;
      content: "max. date range is 90 days";
      color: var(--text);
    }
    `}

  .CalendarDay__default {
    background: transparent;
    border: none;
    color: inherit;
  }

  .CalendarDay__selected_end,
  .CalendarDay__selected_start {
    background: white;
    color: var(--darker);
  }

  .CalendarDay__selected_start {
    border-radius: var(--radius) 0 0 var(--radius);
  }
  .CalendarDay__selected_end {
    border-radius: 0 var(--radius) var(--radius) 0;
  }

  .CalendarDay__hovered_span,
  .CalendarDay__selected_span {
    background: var(--focus);
    color: inherit;
  }

  .DayPickerNavigation_button__default {
    background: transparent;
    border: none !important;
  }

  .CalendarMonth_caption {
    color: inherit;
  }
  .DayPicker_weekHeader {
    color: inherit;
  }
`;

const START_INPUT = "startDate";
const END_INPUT = "endDate";
const MAX_RANGE = 90;

export interface DateRange {
  startDate: moment.Moment | null;
  endDate: moment.Moment | null;
}

export interface Props {
  startDate: moment.Moment | null;
  endDate: moment.Moment | null;
  limitRange: boolean;
  onChange: (params: DateRange) => void;
}

interface State {
  focusedInput: any;
}

export class DateRangeFilter extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      focusedInput: null,
    };
  }

  onDatesChange = ({ startDate, endDate }: DateRange) => {
    // Pull end date within range if currently selected endDate would be outside range
    if (
      this.props.limitRange === true &&
      startDate &&
      startDate !== this.props.startDate
    ) {
      if (!endDate) {
        return;
      }
      const maxEndDate = startDate.clone().add(MAX_RANGE, "days");
      if (endDate.isAfter(maxEndDate)) {
        endDate = maxEndDate;
      }
    }
    this.props.onChange({ startDate, endDate });
  };

  onFocusChange = (focusedInput: any) => {
    this.setState({ focusedInput });
  };

  render() {
    const { startDate, limitRange } = this.props;
    const { focusedInput } = this.state;

    return (
      <Wrapper maxRangeWarning={limitRange}>
        <DateRangePicker
          onDatesChange={this.onDatesChange}
          onFocusChange={this.onFocusChange}
          startDateId={START_INPUT}
          showClearDates={!limitRange}
          displayFormat="MM/DD/YYYY"
          endDateId={END_INPUT}
          isOutsideRange={(day: moment.Moment) => {
            if (limitRange === false) {
              return false;
            }
            if (focusedInput === END_INPUT && startDate) {
              return !(
                day.isAfter(startDate) &&
                day.isBefore(startDate.clone().add(MAX_RANGE, "days"))
              );
            }
            return false;
          }}
          focusedInput={focusedInput}
          hideKeyboardShortcutsPanel
          startDate={this.props.startDate}
          endDate={this.props.endDate}
        />
      </Wrapper>
    );
  }
}
