import axios, { AxiosInstance, AxiosRequestConfig } from "axios";
import { observable, isObservableProp, action } from "mobx";
import moment from "moment";

class Api {
  instance: AxiosInstance;
  csrfToken?: string;

  constructor() {
    this.instance = axios.create({
      baseURL: "/api",
    });
  }

  get(url: string, config: AxiosRequestConfig = {}) {
    return this.instance.get(url, config);
  }

  post(url: string, data: any = {}, options: AxiosRequestConfig = {}) {
    const headers = Object.assign(
      {
        "Content-Type": "application/json",
        "X-Csrftoken": this.csrfToken,
      },
      options.headers
    );

    options.headers = headers;
    return this.instance.post(url, data, options);
  }
}

export const api = new Api();

export abstract class Model {
  @observable id = "";

  @action parse(data: any) {
    Object.entries(data).forEach(([key, value]) => {
      const isProp = isObservableProp(this, key);
      if (isProp) {
        // @ts-ignore
        this[key] = value;
      }
    });
  }
}

export interface SelectOption {
  label: string;
  value: string;
}

export interface Filter {
  fueler: number | null;
  productType: string | null;
  company: number | null;
  register: number | null;
  startDate: moment.Moment | null;
  endDate: moment.Moment | null;
}

export abstract class Store<M extends Model> {
  abstract Model: any;
  abstract target: string;
  models: M[] = observable([]);

  @action async fetch() {
    const response = await api.get(`${this.target}/`);
    this.parse(response.data);
  }

  get(id: any) {
    return this.models.find((model) => model.id === id);
  }

  @action
  parse(data: any) {
    const newModels = data.map((record: any) => {
      const model = new this.Model() as M;
      model.parse(record);
      return model;
    });
    // @ts-ignore
    this.models.replace(newModels);
  }
}
